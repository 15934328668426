import * as React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Helmet,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';

// @ts-ignore
import * as styles from './index.module.css';
import pawSvg from '../images/paw.svg';

import Layout from '../components/layout';
// import PhoneCase from '../components/PhoneCase/phone-case';
import Hr from '../components/Hr/hr';
import DownloadFromStore from '../components/DownloadFromStore/download-from-store';
import SEO from '../components/seo';
import ogImg from '../images/og-image.png';

const IndexPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const isPl = language === 'pl';
  const description = isPl
    ? `Dieta surowa (BARF/RAW) jest bez wątpienia najwłaściwszym sposobem karmienia naszych psów. Ale jest też dietą trudną - aby była właściwie skomponowana, wymaga wielu obliczeń i dostosowania do psa. Dzięki aplikacji BarfPlan planowanie diety będzie dużo łatwiejsze! Stwórz profil swojego psa i komponuj posiłki, a my wszystko za Ciebie policzymy. `
    : `Raw diet is undoubtedly the best way of feeding your dog. But it is quite difficult - it requires many calculations to be prepared properly. With BarfPlan it will be much easier! Create your dog’s profile, start planning meals and we will calculate everything for you.`;

  return (
    <Layout>
      <SEO
        description={description}
        ogUrl="https://getbarfplan.com/"
        imageUrl={ogImg}
      />

      <div className="hero-container">
        <StaticImage
          src="../images/hero.png"
          alt={t('hero-alt')}
          formats={['auto', 'webp', 'avif']}
          loading="eager"
          placeholder="tracedSVG"
          width={1024}
          height={400}
        />
      </div>

      <div className="container bg-white ph-60 md:ph-30 xs:ph-10">
        <section>
          <h2>
            <Trans>Plan for the whole week or just a few days</Trans>
          </h2>

          {/*<div className="side-by-side-section">*/}
          {/*  <PhoneCase />*/}
          {/*</div>*/}
          <div className="side-by-side-section">
            <div
              className={[
                'no-shrink',
                'side-by-side-gap',
                styles.planIphone,
              ].join(' ')}
            >
              {isPl ? (
                <StaticImage
                  src="../images/pl/plan-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/plan-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>

            <div>
              <p>{t('plan-1')}</p>
              <p>{t('plan-2')}</p>
              <p>{t('plan-3')}</p>
              <p>{t('plan-4')}</p>
              <p>{t('plan-5')}</p>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Choose from 150+ types of food</Trans>
          </h2>

          <p>{t('choose-food-1')}</p>

          {isPl ? (
            <StaticImage
              src="../images/pl/choose-food.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              width={840}
              height={343}
            />
          ) : (
            <StaticImage
              src="../images/en/choose-food.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              width={840}
              height={343}
            />
          )}

          <p className="mt-30">{t('choose-food-2')}</p>
          <p>{t('choose-food-3')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>You can adjust what your dog eats and what doesn’t</Trans>
          </h2>

          <div className="side-by-side-section">
            <div className="side-by-side-gap">
              <p>{t('proportions-1')}</p>
              <p>{t('proportions-2')}</p>

              <div className="text-center">
                {isPl ? (
                  <StaticImage
                    src="../images/pl/meat-types.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={368}
                    height={76}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/meat-types.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={363}
                    height={76}
                  />
                )}
              </div>

              <p className="mt-30">{t('proportions-3')}</p>
              <p>{t('proportions-4')}</p>
            </div>

            <div className={['no-shrink', styles.planIphone].join(' ')}>
              {isPl ? (
                <StaticImage
                  src="../images/pl/proportions-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/proportions-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>
          </div>
        </section>

        <Hr className="mt-30" />

        <section>
          <h2>
            <Trans>Planning is easy when you see the trend</Trans>
          </h2>

          <p className="mb-10">
            {t('planning-1')}
            <br />
            <span dangerouslySetInnerHTML={{ __html: t('planning-2') }} />
          </p>

          {isPl ? (
            <StaticImage
              src="../images/pl/balancer.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              width={860}
              height={300}
            />
          ) : (
            <StaticImage
              src="../images/en/balancer.png"
              alt=""
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              width={860}
              height={300}
            />
          )}
          <p className="mt-10">{t('planning-3')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>The app is available for Android and iOS</Trans>
          </h2>

          <p className="text-center">{t('store-1')}</p>

          <DownloadFromStore elementPosition={1} />

          <p className="text-center mt-30">{t('store-2')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>You can share the app</Trans>
          </h2>

          <div className={styles.shareAppSection}>
            <div className={styles.shareAppImage}>
              {isPl ? (
                <StaticImage
                  src="../images/pl/share-app-comic.jpg"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={354}
                  height={501}
                />
              ) : (
                <StaticImage
                  src="../images/en/share-app-comic.jpg"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={354}
                  height={501}
                />
              )}
            </div>

            <div>
              <p>{t('share-app-1')}</p>
              <p>{t('share-app-2')}</p>
              <p className="mb-10">{t('share-app-3')}</p>

              <div className="text-center">
                {isPl ? (
                  <StaticImage
                    src="../images/pl/mark-meal.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={371}
                    height={172}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/mark-meal.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={371}
                    height={172}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Having a hard time with supplements?</Trans>
            <br />
            <Trans>We have you covered!</Trans>
          </h2>

          <div className="side-by-side-section">
            <div className="side-by-side-gap">
              <p>{t('supplements-1')}</p>
              <p>{t('supplements-2')}</p>
              <p>{t('supplements-3')}</p>
              <p>{t('supplements-4')}</p>
            </div>

            <div
              className={['no-shrink', 'mb-20', styles.planIphone].join(' ')}
            >
              {isPl ? (
                <StaticImage
                  src="../images/pl/supplements-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/supplements-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>
          </div>

          <div className="side-by-side-section">
            <div className="side-by-side-gap no-shrink mb-10 md:text-center">
              {isPl ? (
                <StaticImage
                  src="../images/pl/supplement-example.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={554}
                />
              ) : (
                <StaticImage
                  src="../images/en/supplement-example.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={554}
                />
              )}
            </div>

            <div>
              <p>{t('supplements-5')}</p>
              <p>{t('supplements-6')}</p>
              <p>
                <strong>{t('supplements-7')}</strong>
              </p>
              <p>{t('supplements-8')}</p>
            </div>
          </div>

          <div className="side-by-side-section">
            <div className="side-by-side-gap">
              <div>
                <p>{t('supplements-9')}</p>
                <p>
                  {t('supplements-10')}
                  <br />
                  {t('supplements-11')}
                </p>
              </div>
            </div>

            <div className="no-shrink mb-10 md:text-center">
              {isPl ? (
                <StaticImage
                  src="../images/pl/supplement-dosage-tip.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={345}
                />
              ) : (
                <StaticImage
                  src="../images/en/supplement-dosage-tip.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={345}
                />
              )}
            </div>
          </div>

          <div className="side-by-side-section">
            <div className="side-by-side-gap no-shrink mb-10 md:text-center">
              {isPl ? (
                <StaticImage
                  src="../images/pl/supplement-dosage-adjusted.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={387}
                />
              ) : (
                <StaticImage
                  src="../images/en/supplement-dosage-adjusted.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={377}
                  height={387}
                />
              )}
            </div>

            <div>
              <p>{t('supplements-12')}</p>
              <p>{t('supplements-13')}</p>
              <p className="mb-10">{t('supplements-14')}</p>

              <div className="text-center">
                {isPl ? (
                  <StaticImage
                    src="../images/pl/supplements-list-in-calendar.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={371}
                    height={231}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/supplements-list-in-calendar.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={371}
                    height={231}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Keep track of dog’s weight</Trans>
          </h2>

          <div className="side-by-side-section">
            <div
              className={[
                'no-shrink',
                'side-by-side-gap',
                styles.planIphone,
              ].join(' ')}
            >
              {isPl ? (
                <StaticImage
                  src="../images/pl/dogs-weight-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/dogs-weight-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>

            <div>
              <p>{t('dogs-weight-1')}</p>
              <p>{t('dogs-weight-2')}</p>
              <p>{t('dogs-weight-3')}</p>
              <p className="mb-10">{t('dogs-weight-4')}</p>

              <div className="text-center">
                {isPl ? (
                  <StaticImage
                    src="../images/pl/weight-goal.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={326}
                    height={249}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/weight-goal.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={326}
                    height={249}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Try it now!</Trans>
          </h2>

          <p className="text-center">{t('store-3')}</p>

          <DownloadFromStore elementPosition={2} />

          <p className="text-center mt-30">{t('store-4')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>But what if I have more dogs?</Trans>
          </h2>

          <div className="side-by-side-section">
            <div className="side-by-side-gap">
              <p>{t('multi-dogs-1')}</p>
              <p>{t('multi-dogs-2')}</p>
              <p>{t('multi-dogs-3')}</p>
              <p className="mb-20">{t('multi-dogs-4')}</p>

              <div className="text-center">
                {isPl ? (
                  <StaticImage
                    src="../images/pl/multi-dogs-dropdown.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={387}
                    height={233}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/multi-dogs-dropdown.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={399}
                    height={233}
                  />
                )}
              </div>
            </div>

            <div
              className={[
                'no-shrink',
                'mb-20',
                'md:mt-20',
                styles.planIphone,
              ].join(' ')}
            >
              {isPl ? (
                <StaticImage
                  src="../images/pl/multi-dogs-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/multi-dogs-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Are puppies any different?</Trans>
          </h2>

          <div className="side-by-side-section">
            <div
              className={[
                'side-by-side-gap',
                'no-shrink',
                'mb-20',
                styles.planIphone,
              ].join(' ')}
            >
              {isPl ? (
                <StaticImage
                  src="../images/pl/puppies-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              ) : (
                <StaticImage
                  src="../images/en/puppies-iphone.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={280}
                  height={577}
                />
              )}
            </div>

            <div>
              <p>{t('puppies-1')}</p>
              <p>{t('puppies-2')}</p>
              <p>{t('puppies-3')}</p>
              <p className="mb-20">{t('puppies-4')}</p>

              <div className="text-center">
                <img alt="" src={pawSvg} width={185} height={185} />
              </div>
            </div>
          </div>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>We work with different units</Trans>
          </h2>

          <div className="side-by-side-section">
            <div className="side-by-side-gap">
              <p>{t('units-1')}</p>
              <p>{t('units-2')}</p>
              <p>{t('units-3')}</p>
            </div>

            <div className="no-shrink mb-20 md:text-center">
              <div>
                {isPl ? (
                  <StaticImage
                    src="../images/pl/units-daily-ration-percentage.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={340}
                    height={198}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/units-daily-ration-percentage.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={340}
                    height={198}
                  />
                )}
              </div>
              <div>
                {isPl ? (
                  <StaticImage
                    src="../images/pl/units-daily-ration-grams.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={340}
                    height={198}
                  />
                ) : (
                  <StaticImage
                    src="../images/en/units-daily-ration-grams.png"
                    alt=""
                    formats={['auto', 'webp', 'avif']}
                    loading="lazy"
                    placeholder="blurred"
                    width={340}
                    height={198}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="side-by-side-section">
            <div className="side-by-side-gap no-shrink mb-20 md:text-center">
              {isPl ? (
                <StaticImage
                  src="../images/pl/units-and-language.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={383}
                  height={213}
                />
              ) : (
                <StaticImage
                  src="../images/en/units-and-language.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={383}
                  height={213}
                />
              )}
            </div>

            <p className="align-self-center">{t('units-4')}</p>
          </div>

          <div className="side-by-side-section">
            <div className="side-by-side-gap align-self-center">
              <p>{t('units-5')}</p>
              <p>{t('units-6')}</p>
            </div>

            <div className="no-shrink mb-20 md:text-center">
              {isPl ? (
                <StaticImage
                  src="../images/pl/units-preferred.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={407}
                  height={357}
                />
              ) : (
                <StaticImage
                  src="../images/en/units-preferred.png"
                  alt=""
                  formats={['auto', 'webp', 'avif']}
                  loading="lazy"
                  placeholder="blurred"
                  width={407}
                  height={357}
                />
              )}
            </div>
          </div>
        </section>

        <Hr />

        <section className="mb-60">
          <h2>
            <Trans>Download from official stores</Trans>
          </h2>

          <p className="text-center">{t('store-5')}</p>

          <DownloadFromStore elementPosition={3} />
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
