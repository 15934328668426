import React from 'react';
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';

// @ts-ignore
import * as styles from './download-from-store.module.css';

import appStoreBadgeEn from '../../images/download-from-app-store-en.svg';
import appStoreBadgePl from '../../images/download-from-app-store-pl.svg';
import googlePlayBadgeEn from '../../images/get-it-on-google-play-en.svg';
import googlePlayBadgePl from '../../images/get-it-on-google-play-pl.svg';

const langToBadgeMap = {
  en: {
    appStore: appStoreBadgeEn,
    googlePlay: googlePlayBadgeEn,
  },
  pl: {
    appStore: appStoreBadgePl,
    googlePlay: googlePlayBadgePl,
  },
};

enum STORES {
  APP_STORE = 'AppStore',
  GOOGLE_PLAY = 'GooglePlay',
}

type DownloadFromStoreProps = {
  elementPosition?: number;
};

export default function DownloadFromStore(props: DownloadFromStoreProps = {}) {
  const { language } = useI18next();
  const { t } = useTranslation();

  // @ts-ignore
  const badge = langToBadgeMap[language];

  function trackGoToStore(store: STORES) {
    const elementPosition = props.elementPosition ?? 1;
    // @ts-ignore
    window.gtag('event', 'go_to_store', {
      element_position: elementPosition,
      store,
    });

    // @ts-ignore
    window.gtag('event', 'conversion', {
      send_to: 'AW-1017381397/5PjKCLTaudIDEJWEkOUD',
    });
  }

  return (
    <div className={['text-center', styles.wrapper].join(' ')}>
      <a
        href="https://play.google.com/store/apps/details?id=com.getbarfplan"
        target="_blank"
        onClick={() => trackGoToStore(STORES.GOOGLE_PLAY)}
      >
        <img
          src={badge.googlePlay}
          className={styles.googlePlayBadge}
          alt={t('Get it on Google Play')}
          width="221"
          height="66"
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/barfplan/id1583474225"
        target="_blank"
        onClick={() => trackGoToStore(STORES.APP_STORE)}
      >
        <img
          src={badge.appStore}
          alt={t('Download on the AppStore')}
          width="197"
          height="66"
        />
      </a>
    </div>
  );
}
